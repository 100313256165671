interface IDataLayerEvent {
  event: string;
  page: URL;
}

interface ICustomWindow extends Window {
  dataLayer?: IDataLayerEvent[];
}

export const GA_ANALYTICS_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url: URL): void => {
  if (typeof window !== "undefined") {
    const _window = window as ICustomWindow;
    const dataLayer = _window.dataLayer;

    dataLayer &&
      dataLayer.push({
        event: "pageview",
        page: url,
      });
  }
};

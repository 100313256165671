import { type ReactNode, useEffect } from "react";
import { useRouter } from "next/router";

import { type AxiosError, isAxiosError } from "axios";

import {
  getRefreshToken,
  removeAuthTokens,
  setRefreshToken,
  setToken as setServerToken,
} from "@/utils/authentication/authHelpers";
import { authRoutes, getPageRoute } from "@/utils/constants/pageRoutes";
import { getRegion, setRegion } from "@/utils/regions/regionsConfig";

import { LoadingPage } from "../common/LoadingPage";

import { AuthContext } from "./AuthContext";
import { useAuthToken } from "./useAuthToken";
import { useUser } from "./useUser";

import { refreshAuthTokens } from "@/services/auth/refreshAuthTokens";
import { queryClient } from "@/services/queryClient";

interface IAuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const router = useRouter();
  const region = router?.query?.region;
  const lang = router?.query?.lang;

  const currentRegion = getRegion();

  const {
    user,
    setUser,
    client,
    setClient,
    impersonate,
    setImpersonate,
    fetchUser,
    isLoading: isLoadingUser,
  } = useUser();
  const {
    token,
    setToken,
    isLoading: isLoadingToken,
    fetchToken,
  } = useAuthToken();

  const path =
    router.pathname.split("/").length < 3
      ? "/"
      : `/${router?.pathname?.split("/")?.[3]}`;

  const isAuthRoute = authRoutes.includes(path);

  const logout = async (noRedirect?: boolean) => {
    await removeAuthTokens();
    setUser(null);
    setClient(null);
    setImpersonate(null);
    queryClient.clear();

    if (
      router.pathname !==
      getPageRoute("login", region as string, lang as string)
    ) {
      await router.replace(
        noRedirect
          ? getPageRoute("login", region as string, lang as string)
          : `${getPageRoute("login", region as string, lang as string)}?targetPath=${router.asPath}`,
      );
    }

    setToken(null);
  };

  /**
   * @deprecated Can be removed after refactoring the functions where it is used
   */
  const refreshToken = async () => {
    const refreshToken = await getRefreshToken();
    if (router?.query?.t) return;

    if (!refreshToken) {
      logout();
      return;
    }

    try {
      const { token, refresh_token } = await refreshAuthTokens(
        refreshToken,
        region as string,
      );

      if (!token || !refreshToken) return;

      setToken(token);
      setServerToken(token);
      setRefreshToken(refresh_token);

      router.reload();
      return;
    } catch (error: unknown) {
      if (error instanceof Error && isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 401) {
          if (!router?.query?.t) {
            //If there is a router query t we do not redirect towards targetPath as this is needed for contact persons to view the page.
            logout();
          }
        }
      }
    }
  };

  useEffect(() => {
    if (impersonate) return;
    if (!token) (async () => await fetchToken(logout))();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, impersonate, token, region]);

  useEffect(() => {
    if (router.isReady && router.query.region !== currentRegion?.slug)
      setRegion(region as string);
  }, [currentRegion?.slug, region, router.isReady, router.query.region]);

  useEffect(() => {
    if (token && !user)
      (async () => await fetchUser(token, logout, refreshToken))();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, region]);

  if (
    (isLoadingToken || isLoadingUser || !token || (token && !user)) &&
    !isAuthRoute
  )
    return <LoadingPage />;

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user,
        client,
        isLoading: isLoadingToken || isLoadingUser,
        logout,
        refreshToken,
        impersonate,
        setImpersonate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import React, { type ReactNode } from "react";

import { useAuthorize } from "@/utils/hooks/useAuthorize";

interface IAuthorize {
  requiredPermissions: string[];
  permissions?: string[];
  children?: ReactNode;
  alternative?: ReactNode;
}

const Authorize = ({
  requiredPermissions,
  permissions,
  children,
  alternative,
}: IAuthorize) => {
  const granted = useAuthorize(requiredPermissions, permissions || []);

  if (!granted) return <>{alternative ? alternative : null}</>;

  return <>{children ? children : null}</>;
};

export default Authorize;

import * as React from "react";
import { type SVGProps } from "react";
export const IconCloseCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    shapeRendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M12.055 6.163a.143.143 0 0 0-.143-.142l-1.179.01-1.767 2.123-1.78-2.108H6.004a.142.142 0 0 0-.143.143c0 .034.013.066.035.092l2.333 2.76-2.312 2.774a.143.143 0 0 0 .11.235l1.18-.01 1.765-2.122 1.781 2.107 1.179.001a.142.142 0 0 0 .142-.143.148.148 0 0 0-.034-.093l-2.33-2.757 2.312-2.777a.144.144 0 0 0 .032-.093Z" />
      <path d="M8.939 1A8 8 0 1 0 9 17.001a8 8 0 0 0-.062-16Zm.056 14.643a6.644 6.644 0 0 1-.05-13.286 6.644 6.644 0 0 1 .05 13.286Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="m.939 1.03 16-.06.061 16-16 .06z" />
      </clipPath>
    </defs>
  </svg>
);

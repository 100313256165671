export const pageRoutes = {
  main: "/",
  login: "/login",
  impersonate: "/login/impersonate",
  gigs: "/gigs?tab=open",
  activateAccount: "/activate-account",
  gigsWelcome: "/gigs?displayWelcomeMessage=true",
  gigsOpen: "/gigs?tab=open",
  gigsReadyForExecution: "/gigs?tab=ready-for-execution",
  gigsPayout: "/gigs?tab=payout",
  gigsArchived: "/gigs?tab=archived",
  gigCreate: "/gigs/create",
  gigDetail: "/gigs/{gigSlug}",
  calendar: "/calendar",
  labels: "/labels",
  favorites: "/favorites",
  blocked: "/blocked",
  reviews: "/my-account/reviews",
  register: "/register",
  registered: "/registered",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  settings: "/settings",
  accounts: "/accounts",
  workspace: "settings/accounts",
  verify: "/verify",
  myAccount: "/my-account",
  settingsPage_accounts: "/settings/?tab=workspaces",
  settingsPage_profile: "/settings?tab=businessProfile",
  settingsPages_referenceNumbers: "/settings?tab=referenceNumbers",
  settingsPages_jobTemplates: "/settings/job-templates",
  settingsPages_locations: "/settings?tab=locations",
  settingsPages_contacts: "/settings/contacts",
  settingsPages_favoriteTags: "/settings/labels",
  settingsPages_apiKeys: "/settings/api-keys",
  maintenance: "/maintenance",
};

export const authRoutes = [
  pageRoutes.login,
  pageRoutes.register,
  pageRoutes.forgotPassword,
  pageRoutes.registered,
  pageRoutes.main,
  pageRoutes.resetPassword,
  pageRoutes.activateAccount,
  pageRoutes.maintenance,
  pageRoutes.impersonate,
  pageRoutes.verify,
];
export const protectedRoutes = [
  pageRoutes.myAccount,
  pageRoutes.gigCreate,
  pageRoutes.labels,
  pageRoutes.favorites,
  pageRoutes.blocked,
  pageRoutes.settings,
  pageRoutes.calendar,
  pageRoutes.gigs,
  pageRoutes.gigDetail,
  pageRoutes.reviews,
];

type RouteName = keyof typeof pageRoutes;

type query = string | string[] | undefined | null;

export const getPageRoute = (
  routeName: RouteName,
  region: query,
  lang: query,
): string => {
  const baseRoute = pageRoutes[routeName];

  if (region === null || lang === null) return `/${baseRoute}`;

  return `/${region}/${lang}${baseRoute}`;
};

import { Radio as MantineRadio, type RadioProps } from "@mantine/core";

export interface IRadioButton extends RadioProps {
  id?: string;
  name?: string;
  value: string;
  label: string;
  onChange?: (...event: unknown[]) => void;
  defaultChecked?: boolean;
  dataTestId?: string;
  classNames?: { [x: string]: string };
}

const BaseClasses = {
  label: "mb-0 text-sm",
};

export const RadioButton = ({
  id,
  name,
  value,
  label,
  onChange,
  defaultChecked,
  dataTestId,
  classNames,
  ...props
}: IRadioButton) => (
  <MantineRadio
    data-component="radioButton"
    id={id}
    name={name}
    value={value}
    label={label}
    onChange={onChange}
    defaultChecked={defaultChecked}
    color="navyBlueShades"
    variant="outline"
    styles={{ label: { marginBottom: "0px" } }}
    data-testid={dataTestId}
    classNames={{
      ...BaseClasses,
      ...classNames,
    }}
    {...props}
  />
);

RadioButton.displayName = "RadioButton";

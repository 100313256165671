import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { JOB_SLUG } from "@/utils/enums";

import { BigBadge, COLOR } from "../ui";

import { type IJob } from "@/types";

interface IGigsBadge {
  job: IJob;
}

export const GigsBadge = ({ job }: IGigsBadge) => {
  const router = useRouter();
  const { t } = useTranslation();

  let badgeTitle = "";
  let color = COLOR.GREEN;

  switch (true) {
    case job.countUnhandledClaimsFromContractors &&
      job.countUnhandledClaimsFromContractors > 0:
      badgeTitle = t("job.numberOfClaims", {
        count: job.countUnhandledClaimsFromContractors,
      });
      color = COLOR.RED;
      break;
    case job.countUnreadSubmittedClaimsByContractor &&
      job.countUnreadSubmittedClaimsByContractor > 0:
      badgeTitle = t("job.numberOfClaims", {
        count: job.countUnreadSubmittedClaimsByContractor,
      });
      color = COLOR.RED;
      break;
    case job.countUnreadCancelledMatchesByContractor > 0:
      badgeTitle = t("job.numberOfUnreadCancelledMatches", {
        count: job.countUnreadCancelledMatchesByContractor,
      });

      color = COLOR.RED;

      break;
    case job.isActionRequired && router?.query?.tab === JOB_SLUG.PAYOUT:
      badgeTitle = t("job.hoursSubmitted");

      color = COLOR.ACCENT_BLUE;
      break;
    case job.substituteRequestCount > 0:
      badgeTitle = t("job.numberOfSubstituteRequests", {
        count: job.substituteRequestCount,
      });
      color = COLOR.ORANGE;
      break;
    case job.countPendingWorkIntents > 0:
      badgeTitle = t("job.numberOfWorkIntents", {
        count: job.countPendingWorkIntents,
      });

      color = COLOR.GREEN;
      break;
    case job.countPendingWorkIntents === 0 &&
      router?.query?.tab === JOB_SLUG.OPEN:
      badgeTitle = t("job.numberOfWorkIntents", {
        count: job.countPendingWorkIntents,
      });
      color = COLOR.WHITE;
      break;
    default:
      badgeTitle = "";
      break;
  }

  if (!badgeTitle) return null;

  return <BigBadge styleVariant={color}>{badgeTitle}</BigBadge>;
};

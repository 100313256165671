import { type HTMLAttributes } from "react";

import { cn } from "./utils/cn";

interface ITableCell extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  smallPadding?: boolean;
}

export const TableCell = ({
  children,
  smallPadding,
  className,
}: ITableCell) => (
  <div
    data-component="tableCell"
    className={`${cn(
      "table-cell py-2 align-middle text-sm first:pl-2 last:pr-2 md:py-0 md:xl:last:pr-10 md:xl:first:pl-10",
      {
        "md:xl:last:pr-5 md:xl:first:pl-5": smallPadding,
      },
      className,
    )}`}
  >
    {children}
  </div>
);

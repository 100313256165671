import React, { forwardRef } from "react";

import {
  Textarea as MantineTextarea,
  type TextareaProps as MantineTextareaProps,
} from "@mantine/core";

export interface ITextarea extends Omit<MantineTextareaProps, "radius"> {
  "data-testid"?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, ITextarea>(
  ({ minRows = 8, maxRows = 20, ...props }, ref) => (
    <MantineTextarea
      data-component="textArea"
      radius={5}
      autosize
      minRows={minRows}
      maxRows={maxRows}
      size="md"
      ref={ref}
      classNames={{
        label: "!font-medium !text-xs mb-2",
        input: "bg-ultraLightgray text-sm",
        error: "font-base text-xs pt-2",
      }}
      className="text-lg::placeholder text-sm"
      {...props}
    />
  ),
);

Textarea.displayName = "Textarea";

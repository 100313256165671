import {
  Avatar as MantineAvatar,
  type AvatarProps as MantineAvatarProps,
} from "@mantine/core";

interface IAvatarProps extends MantineAvatarProps {
  "data-testid"?: string;
}

export const Avatar = ({
  size = 50,
  alt = "",
  radius = 50,
  ...props
}: IAvatarProps) => (
  <MantineAvatar
    data-component="Avatar"
    size={size}
    alt={alt}
    radius={radius}
    data-testid="avatar"
    {...props}
  />
);

Avatar.displayName = "Avatar";

import React, { forwardRef } from "react";

import { type ISelect, Select } from "../Select";

import { useTextInputContext } from "./TextInputContext";

export const TextInputSelect = forwardRef<HTMLInputElement, ISelect>(
  ({ error, ...props }, ref) => {
    const { dispatch } = useTextInputContext();

    React.useEffect(() => {
      dispatch({ type: "SET_SELECT_ERROR", payload: error });
    }, [error, dispatch]);

    return (
      <Select
        classNames={{
          input: "pl-3 !bg-transparent w-[120%] ",
          rightSection: "right-2",
          root: "w-auto",
        }}
        variant="unstyled"
        rightSectionWidth={20}
        clearable={false}
        searchable={false}
        ref={ref}
        {...props}
      />
    );
  },
);

TextInputSelect.displayName = "TextInputSelect";

import { useState } from "react";
import { useRouter } from "next/router";

import { type AxiosError, isAxiosError } from "axios";

import { getClient } from "@/services/user/getClient";
import { getUser } from "@/services/user/getUser";
import { type IClient, type IUser } from "@/types";

interface IErrorResponse {
  code: number;
  message: string;
  identifier: string;
}

export const useUser = () => {
  const router = useRouter();
  const region = router?.query?.region;

  const [user, setUser] = useState<null | IUser>(null);
  const [client, setClient] = useState<null | IClient>(null);
  const [isLoading, setLoading] = useState(false);
  const [impersonate, setImpersonate] = useState<string | null>(null);

  const fetchUser = async (
    token: string,
    logout: () => Promise<void>,
    refreshToken: () => Promise<void>,
  ) => {
    setLoading(true);

    try {
      const fetchedUser = await getUser(token, impersonate, region as string);

      if (fetchedUser) {
        setUser(fetchedUser);

        if (!fetchedUser.workspaceId) {
          const fetchedClient = await getClient(
            token,
            impersonate,
            region as string,
          );

          if (fetchedClient) setClient(fetchedClient);
        }
      }

      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);

      if (isAxiosError(e)) {
        const error = e as unknown as AxiosError;
        const data = error.response?.data as unknown as IErrorResponse;

        if (data.code === 401 && data?.message === "Expired JWT Token")
          await refreshToken();

        await logout();
      }
    }
  };

  return {
    user,
    setUser,
    client,
    setClient,
    impersonate,
    setImpersonate,
    isLoading,
    fetchUser,
  };
};

import {
  Badge as MantineBadge,
  type BadgeProps as MantineBadgeProps,
} from "@mantine/core";
import clsx from "clsx";

import { COLOR } from "./utils/enums";

interface IBadgeProps
  extends Omit<MantineBadgeProps, "color" | "gradient" | "variant" | "size"> {
  styleVariant?:
    | "greenLight"
    | "red"
    | "accentBlue"
    | "warning"
    | "gray"
    | "black"
    | "purple"
    | "pink"
    | "pinkLight";
  "data-testid"?: string;
}

export const Badge = ({
  styleVariant = COLOR.ACCENT_BLUE,
  className,
  ...props
}: IBadgeProps) => (
  <MantineBadge
    data-component="Badge"
    className={clsx(
      "whitespace-wrap h-[1.375rem] max-w-full overflow-hidden border p-2.5 text-xs font-medium normal-case",
      {
        "border-accentBlue bg-accentBlueLight text-accentBlue":
          styleVariant === COLOR.ACCENT_BLUE,
        "border-green bg-greenLight text-green":
          styleVariant === COLOR.GREEN_LIGHT,
        "border-red-500 bg-red-50 text-red-500": styleVariant === COLOR.RED,
        "border-purple bg-purpleLight text-purple":
          styleVariant === COLOR.PURPLE,
        "border-warning bg-warningLight text-warning":
          styleVariant === COLOR.WARNING,
        "border-black bg-gray-50 text-black": styleVariant === COLOR.BLACK,
        "border-gray-800 bg-gray-50 text-gray-800": styleVariant === COLOR.GRAY,
        "border-pink bg-pinkLight text-pink": styleVariant === COLOR.PINK_LIGHT,
        "border-pink bg-pink text-white": styleVariant === COLOR.PINK,
      },
      className,
    )}
    {...props}
  />
);

Badge.displayName = "Badge";

interface HRProps {
  className?: string;
}

export const HR = ({ className }: HRProps) => (
  <hr
    data-component="hr"
    className={`h-px w-full border-none bg-gray-200 ${className}`}
  />
);

HR.displayName = "HR";

import React, { forwardRef, type Ref } from "react";

import {
  ActionIcon as MantineActionIcon,
  type ActionIconProps,
} from "@mantine/core";
import clsx from "clsx";

import { cn } from "./utils/cn";
import { ACTION_ICON_STYLE_VARIANT } from "./utils/enums";
import formatIcon from "./utils/formatIcon";

export interface IActionIcon extends Omit<ActionIconProps, "classNames"> {
  "data-testid"?: string;
  title: string;
  disabled?: boolean;
  styleVariant?: "default" | "transparent" | "small" | "round";
  onClick?: () => void;
  icon: React.ReactNode;
  iconSize?: number;
  strokeWidth?: number;
  className?: string;

  /**
   * @deprecated Can be removed and replaced by iconSize
   */
  width?: string;
  /**
   * @deprecated Can be removed and replaced by iconSize
   */
  height?: string;
}

export const ActionIcon = forwardRef(
  (
    {
      title,
      disabled = false,
      styleVariant = "default",
      icon,
      iconSize = 20,
      strokeWidth = 1,
      onClick,
      className,
      ...props
    }: IActionIcon,
    ref: Ref<HTMLButtonElement & HTMLAnchorElement>,
  ) => {
    const variantClassNames = clsx({
      "border border-gray-200 hover:border-black text-black child:p-10 w-[36px] h-[36px] disabled:text-gray-500":
        styleVariant === ACTION_ICON_STYLE_VARIANT.DEFAULT,
      "border text-black child:p-10 w-[36px] h-[36px] disabled:text-gray-500":
        styleVariant === ACTION_ICON_STYLE_VARIANT.TRANSPARENT,
      "child:p-10 w-[37px] h-[37px] text-black rounded-full bg-primary hover:bg-turquoise-700 disabled:turquoise-50 disabled:text-gray-800":
        styleVariant === ACTION_ICON_STYLE_VARIANT.ROUND,
      "border border-gray-200 hover:border-black text-black hover:bg-gray-50 disabled:text-gray-500":
        styleVariant === ACTION_ICON_STYLE_VARIANT.SMALL,
    });

    return (
      <MantineActionIcon
        variant="transparent"
        title={title}
        disabled={disabled}
        data-component="ActionIcon"
        className={cn(variantClassNames, className)}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {formatIcon(icon, { stroke: strokeWidth, size: iconSize })}
      </MantineActionIcon>
    );
  },
);

ActionIcon.displayName = "ActionIcon";

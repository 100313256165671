import { useTranslation } from "next-i18next";

import { MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";

import { MantineDatesProviderLocale } from "@/utils/enums";

import "dayjs/locale/en";
import "dayjs/locale/nl";
import "dayjs/locale/fr";
import "dayjs/locale/nl-be";

import { mantineThemeOverride } from "../ui";

interface IDashboardMantineProvider {
  children: React.ReactNode;
}

const DashboardMantineProvider = ({ children }: IDashboardMantineProvider) => {
  const { i18n } = useTranslation();

  let datesProviderLocale;

  switch (i18n.language) {
    case "nl-NL":
      datesProviderLocale = MantineDatesProviderLocale.NETHERLANDS;
      break;
    case "nl-BE":
      datesProviderLocale = MantineDatesProviderLocale.BELGIUM;
      break;
    case "fr-FR":
      datesProviderLocale = MantineDatesProviderLocale.FRANCE;
      break;
    default:
      datesProviderLocale = MantineDatesProviderLocale.ENGLISH;
  }

  return (
    <MantineProvider theme={mantineThemeOverride}>
      <DatesProvider
        settings={{ firstDayOfWeek: 1, locale: datesProviderLocale }}
      >
        {children}
      </DatesProvider>
    </MantineProvider>
  );
};

export { DashboardMantineProvider as MantineProvider };

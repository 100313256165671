import * as React from "react";
import { type SVGProps } from "react";
export const IconEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8234 13.7655H11.8822C11.383 13.7655 10.9043 13.9638 10.5513 14.3168C10.1983 14.6698 10 15.1485 10 15.6477V24.1178C10 24.617 10.1983 25.0958 10.5513 25.4488C10.9043 25.8017 11.383 26.0001 11.8822 26.0001H20.3523C20.8515 26.0001 21.3303 25.8017 21.6833 25.4488C22.0362 25.0958 22.2345 24.617 22.2345 24.1178V23.1767"
      stroke="#1B1C1E"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.4212 13.374C25.7918 13.0034 26.0001 12.5007 26.0001 11.9765C26.0001 11.4523 25.7918 10.9496 25.4212 10.5789C25.0505 10.2083 24.5478 10 24.0236 10C23.4994 10 22.9967 10.2083 22.6261 10.5789L14.7065 18.4702V21.2936H17.5299L25.4212 13.374V13.374Z"
      stroke="#1B1C1E"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.2925 11.8828L24.1158 14.7061"
      stroke="#1B1C1E"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

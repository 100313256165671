import clsx from "clsx";

interface DividerProps {
  "data-testid"?: string;
  className?: string;
}

export const Divider = ({ className, ...props }: DividerProps) => (
  <div
    data-component="divider"
    className={clsx(`block h-[1px] w-full bg-[#CCCCCC]`, className)}
    {...props}
  />
);

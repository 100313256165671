import { type ReactElement } from "react";
import Link from "next/link";

interface ILinkText {
  pathName: string;
  query?: string;
  locale?: string;
  classNames?: string;
  onClick?: () => void;
  children: ReactElement | string | ReactElement[];
  dataUITestClass?: string;
}

/** @deprecated dont use this anymore, instead use the next link */
export const LinkText = ({
  pathName,
  query,
  locale,
  classNames,
  onClick,
  children,
  dataUITestClass,
}: ILinkText) => (
  <Link
    data-component="linkText"
    href={pathName + (query ? `?${query}` : "")}
    locale={locale}
    className={`text-xs font-medium text-accentBlue ${classNames ?? ""}`}
    data-testid={dataUITestClass}
    onClick={onClick}
  >
    <>{children}</>
  </Link>
);

LinkText.displayName = "LinkText";

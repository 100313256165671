import { Loader as MantineLoader, type MantineTheme } from "@mantine/core";

interface ILoader {
  variant?: MantineTheme["loader"];
}

export const Loader = ({ variant = "oval", ...props }: ILoader) => (
  <MantineLoader
    data-component="loader"
    data-testid="loader"
    variant={variant}
    {...props}
  />
);

Loader.displayName = "Loader";

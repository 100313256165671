import { useTranslation } from "next-i18next";

import { IconHeartFilled } from "@tabler/icons-react";

import { Badge } from "../ui";
import { cn } from "../ui/utils/cn";

interface IFavoriteBadgeProps {
  isFavorite?: boolean;
  small?: boolean;
}

export const FavoriteBadge = ({
  isFavorite,
  small = false,
}: IFavoriteBadgeProps) => {
  const { t } = useTranslation();

  if (!isFavorite) return null;

  return (
    <Badge styleVariant="pinkLight" className={cn({ "h-5 px-2 py-1": small })}>
      {small ? (
        <IconHeartFilled size={14} />
      ) : (
        <div className="flex items-center gap-1">
          {t("job.isExclusivelyForFavorites")}
        </div>
      )}
    </Badge>
  );
};

import React, { type ReactNode, useState } from "react";

import { IconChevronDown } from "@tabler/icons-react";

interface IAccordionItem {
  controlContent: ReactNode;
  panelContent: ReactNode;
  endContent?: ReactNode;
  style?: "light" | "dark";
  chevronPosition?: "left" | "right";
}
export const AccordionItem = ({
  controlContent,
  panelContent,
  endContent,
  style = "light",
  chevronPosition = "left",
}: IAccordionItem) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div
        className={`${
          style === "dark"
            ? "bg-lightBlue hover:bg-lightBlue/50"
            : "hover:bg-gray-50"
        } cursor-pointer rounded-md px-5 py-3`}
      >
        <div className="relative flex w-full flex-row items-center justify-between gap-2">
          {chevronPosition === "left" && (
            <span
              onClick={() => setIsActive(!isActive)}
              className={`absolute left-0 top-1 transition-transform duration-300 ${
                isActive ? "rotate-180" : ""
              }`}
            >
              {<IconChevronDown size={18} />}
            </span>
          )}
          <div
            onClick={() => setIsActive(!isActive)}
            className={`${
              chevronPosition === "left" && "ml-9"
            } flex w-full flex-row justify-between ${
              style === "dark" && "font-medium"
            } leading-8`}
          >
            {controlContent}
          </div>
          {chevronPosition === "right" ? (
            <span className="mr-9">{endContent}</span>
          ) : (
            <>{endContent}</>
          )}
          {chevronPosition === "right" && (
            <span
              onClick={() => setIsActive(!isActive)}
              className={`absolute right-0 top-1 transition-transform duration-300 ${
                isActive ? "rotate-180" : ""
              }`}
            >
              {<IconChevronDown size={18} />}
            </span>
          )}
        </div>

        <div
          onClick={() => setIsActive(!isActive)}
          className={`transition-max-height overflow-hidden duration-200 ease-in-out ${
            isActive ? "max-h-[1000px]" : "max-h-0"
          } ${chevronPosition === "left" && "ml-9"} ${
            endContent ? "mr-14" : "mr-9"
          } font-normal leading-6`}
        >
          {panelContent}
        </div>
      </div>
    </>
  );
};

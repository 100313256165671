import axios from "axios";

export const refreshTokenKey = "YO_DKHCQ8";

export const getToken = async () => {
  const response = await axios("/api/auth/get-token", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const { token } = response.data as { token: string | null };

  return token;
};

export const setToken = async (newToken: string | null) => {
  await axios.post(
    "/api/auth/set-token",
    { token: newToken },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const getRefreshToken = async () => {
  const response = await axios("/api/auth/get-refresh-token", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const { refreshToken } = response.data as { refreshToken: string };

  if (!refreshToken) return null;

  return refreshToken;
};

export const setRefreshToken = async (refreshToken: string) => {
  await axios.post(
    "/api/auth/set-refresh-token",
    { token: refreshToken },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const removeAuthTokens = async () => {
  await axios.post(
    "/api/auth/remove-tokens",
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

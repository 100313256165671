import { type ReactElement, useEffect, useState } from "react";

import { type IAssignmentEvent, init } from "@eppo/js-client-sdk";
import { captureException } from "@sentry/nextjs";
import axios, { type AxiosError } from "axios";

import { useAuth } from "../auth/AuthContext";
import { LoadingPage } from "../common/LoadingPage";

interface IEppoRandomizationProvider {
  waitForInitialization?: boolean;
  children: ReactElement;
  loadingComponent?: ReactElement;
}

export interface IAssignmentEventWithClient extends IAssignmentEvent {
  clientUuid?: string;
  workspaceUuid?: string;
}

const EppoRandomizationProvider = ({
  waitForInitialization = true,
  children,
  loadingComponent = <LoadingPage />,
}: IEppoRandomizationProvider): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const { user, client } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        await init({
          apiKey: process.env.NEXT_PUBLIC_EPPO_SDK_KEY ?? "",
          requestTimeoutMs: 3000,
          assignmentLogger: {
            logAssignment: async (assignment: IAssignmentEvent) => {
              await axios.post<void, AxiosError, IAssignmentEventWithClient>(
                "/api/big-query",
                {
                  ...assignment,
                  workspaceUuid: user?.workspaceId ?? "no-workspace-id",
                  clientUuid: user?.clientId
                    ? user?.clientId
                    : client?.id ?? "no-client-id",
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );
            },
          },
        });

        setIsLoading(false);
      } catch (error) {
        captureException(error);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!waitForInitialization || !isLoading) return children;

  return loadingComponent;
};

export default EppoRandomizationProvider;

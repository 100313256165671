import React from "react";

import { Menu } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";

import { cn } from "./utils/cn";
import { ActionIcon, buttonVariants } from ".";

interface IKebabMenu {
  title?: string;
  outline?: boolean;
  disabled?: boolean;
  content: {
    key: number;
    onClick: () => void;
    title: string;
  }[];
}

export const KebabMenu = ({
  title,
  content,
  outline = false,
  disabled = false,
}: IKebabMenu) => {
  const defaultStyling = `text-black flex items-center gap-1 bg-transparent font-medium py-1 h-10 cursor-pointer`;

  return (
    <Menu
      position="bottom-end"
      data-component="kebabMenu"
      width={"18.75rem"}
      shadow="md"
      withinPortal
      data-testid="kebab-menu"
      classNames={disabled ? { item: "cursor-not-allowed" } : {}}
    >
      <Menu.Target>
        <div
          className={cn(
            outline && buttonVariants["secondary"],
            outline && "pr-[6px]",
            defaultStyling,
            disabled ? "cursor-not-allowed" : "",
          )}
        >
          {title}
          <ActionIcon
            title={title ?? ""}
            icon={<IconDotsVertical />}
            disabled={disabled}
            styleVariant={!outline ? "default" : "transparent"}
          />
        </div>
      </Menu.Target>

      {!disabled && (
        <Menu.Dropdown>
          {content.map((c, index) => (
            <Menu.Item
              key={index}
              onClick={disabled ? () => {} : c.onClick}
              className="text-sm"
            >
              {c.title}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
};

KebabMenu.displayName = "KebabMenu";

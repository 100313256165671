import { IconCheck } from "@tabler/icons-react";
import clsx from "clsx";

interface Step {
  label: string;
}

interface StepperProps {
  steps: Step[];
  active: number;
  stepsCompleted: number[];
  completedLabel: string;
  className?: string;
  onStepClick: (index: number) => void;
}

export const Stepper = ({
  steps,
  active,
  className,
  stepsCompleted,
  completedLabel,
  onStepClick,
}: StepperProps) => (
  <div
    className={clsx(
      `flex w-full flex-col items-start md:flex-row md:items-center`,
      className,
    )}
  >
    {steps.map((step, index) => (
      <>
        <div
          key={index}
          onClick={() => (index < active ? onStepClick(index) : null)}
          className={`${index < active && "cursor-pointer"} flex w-auto`}
        >
          <div className={`flex flex-auto flex-row gap-3`}>
            <div
              className={`flex h-10 w-10 flex-auto items-center justify-center whitespace-nowrap`}
            >
              <div
                className={`flex h-10 w-10 content-center justify-center rounded-full !leading-[36px] md:!leading-[40px] ${
                  index === 0 && active === 1
                    ? "bg-lightBlue text-white"
                    : "!bg-transparent"
                } ${
                  index <= active
                    ? "border-2 border-lightBlue text-lightBlue"
                    : "border border-gray-500 text-gray-500"
                } align-middle font-["Gotham"] font-bold md:text-2xl`}
              >
                {index + 1}
              </div>
            </div>
            <div className="flex flex-auto flex-col justify-center">
              <span
                className={`content-center whitespace-nowrap text-sm !font-medium md:text-lg ${
                  index <= active ? "text-gray-900" : "text-gray-500"
                }`}
              >
                {step.label}
              </span>
              <span>
                {completedLabel &&
                active === 1 &&
                index === 0 &&
                stepsCompleted.length > 0 ? (
                  <span className="flex items-center text-xs font-medium text-green">
                    {completedLabel}{" "}
                    <IconCheck className="align-middle" size={12} />
                  </span>
                ) : (
                  <></>
                )}{" "}
              </span>
            </div>
          </div>
        </div>
        {index < steps.length - 1 && (
          <div
            className={`my-2 ml-5 flex h-[24px] w-[1px] align-middle md:mx-4 md:h-[1px] md:w-auto md:flex-1 ${
              active === 1 ? "bg-lightBlue" : "bg-[#CCCCCC]"
            }`}
          ></div>
        )}
      </>
    ))}
  </div>
);

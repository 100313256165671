import React from "react";

type Action = { type: "SET_SELECT_ERROR"; payload: React.ReactNode | null };
type Dispatch = (action: Action) => void;
type State = { selectError: React.ReactNode | null };
type TextProviderProps = { children: React.ReactNode };

export const TextInputContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function textInputReducer(_state: State, action: Action) {
  switch (action.type) {
    case "SET_SELECT_ERROR":
      return { selectError: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const TextInputProvider = ({ children }: TextProviderProps) => {
  const [state, dispatch] = React.useReducer(textInputReducer, {
    selectError: null,
  });

  const value = { state, dispatch };

  return (
    <TextInputContext.Provider value={value}>
      {children}
    </TextInputContext.Provider>
  );
};

export const useTextInputContext = () => {
  const context = React.useContext(TextInputContext);

  if (context === undefined) {
    throw new Error(
      "useTextInputContext must be used within a TextInputProvider",
    );
  }

  return context;
};

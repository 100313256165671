import {
  UnstyledButton as MantineUnstyledButton,
  type UnstyledButtonProps as MantineUnstyledButtonProps,
} from "@mantine/core";

interface IUnstyledButton extends MantineUnstyledButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

export const UnstyledButton = (props: IUnstyledButton) => (
  <MantineUnstyledButton data-component="unstyledButton" {...props} />
);

UnstyledButton.displayName = "UnstyledButton";

import { type HTMLAttributes, type ReactNode } from "react";

import { cn } from "./utils/cn";
import { COLOR } from "./utils/enums";

interface IBigBadgeProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  styleVariant?: "green" | "red" | "white" | "accentBlue" | "orange";
  "data-testid"?: string;
  children: ReactNode;
}

export const BigBadge = ({
  styleVariant = COLOR.GREEN,
  className,
  children,
  ...props
}: IBigBadgeProps) => (
  <div
    data-component="BigBadge"
    className={cn(
      "grid h-9 min-w-[140px] max-w-full place-content-center whitespace-nowrap rounded-full border border-solid px-6 py-1 text-sm font-medium lowercase",
      {
        "border-green bg-white text-green": styleVariant === COLOR.GREEN,
        "border-accentBlue bg-white text-accentBlue":
          styleVariant === COLOR.ACCENT_BLUE,
        "border-orange bg-white text-orange": styleVariant === COLOR.ORANGE,
        "group-hover:bg-greenLight": styleVariant === COLOR.GREEN,
        "border-red-500 bg-white text-red-500": styleVariant === COLOR.RED,
        "group-hover:bg-red-50": styleVariant === COLOR.RED,
        "border-gray-200 bg-white text-black": styleVariant === COLOR.WHITE,
        "": styleVariant === COLOR.WHITE,
        className,
      },
    )}
    {...props}
  >
    {children}
  </div>
);

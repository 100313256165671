import * as React from "react";
import { type SVGProps } from "react";

interface ILogoProps extends SVGProps<SVGSVGElement> {
  "data-testid"?: string;
}

export const Logo = (props: ILogoProps) => (
  <svg
    shapeRendering="geometricPrecision"
    width="1em"
    height="1em"
    viewBox="0 0 49 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M48.1297 0.471719C48.1086 2.58469 48.1199 4.69766 48.1199 6.81064V7.25006H0V0.00751145H0.419139C16.1609 0.00751145 31.9019 0.00901375 47.6437 0C48.0125 0 48.1342 0.0766168 48.1297 0.471719Z"
      fill="#10D1BB"
    />
    <path
      d="M35.771 12.5584C42.4652 12.626 48.086 18.1815 48.1026 25.2182C48.1183 32.0799 42.3818 37.7068 35.4585 37.697C28.4248 37.697 22.9535 31.9109 22.9602 25.0432C22.9677 17.8758 28.9581 12.4901 35.771 12.5584ZM30.3575 27.9802C31.165 30.305 33.0879 31.6465 35.5381 31.6533C37.6721 31.651 39.2871 30.7406 40.3049 28.8568C41.2115 27.1787 41.3828 25.3692 41.0681 23.5154C40.6031 20.7805 38.7117 18.8763 36.1992 18.6262C33.9833 18.4061 31.9935 19.1858 30.8457 21.2199C29.6281 23.3779 29.553 25.6644 30.3575 27.9802Z"
      fill="white"
    />
    <path d="M48.0883 42.78V50H0.00219727V42.78H48.0883Z" fill="#10D1BB" />
    <path
      d="M12.0086 23.4117C12.4585 22.3549 12.8882 21.3491 13.3148 20.3418C14.3537 17.8908 15.3887 15.4383 16.4373 12.9918C16.4869 12.8762 16.6507 12.7327 16.7626 12.7319C19.1249 12.7169 21.4865 12.7207 23.915 12.7207C23.6709 13.2052 23.4493 13.6528 23.2202 14.0975C20.8871 18.6239 18.5533 23.1488 16.2203 27.6745C16.1459 27.8179 16.0896 27.9742 15.9972 28.1041C15.4255 28.9056 15.3347 29.801 15.3549 30.7609C15.3977 32.8476 15.3685 34.9358 15.3685 37.0232V37.4566H8.62542V37.024C8.62542 34.7112 8.60664 32.3984 8.63744 30.0864C8.6457 29.4629 8.49622 28.9251 8.2168 28.3805C6.24429 24.5377 4.28756 20.6858 2.32556 16.8377C1.68033 15.5728 1.03284 14.3093 0.387609 13.0452C0.343292 12.9588 0.304232 12.8701 0.244141 12.7432C0.403383 12.7342 0.521313 12.7214 0.639243 12.7214C2.82733 12.7207 5.01467 12.7259 7.202 12.7139C7.48143 12.7132 7.61588 12.795 7.7233 13.0579C9.03855 16.2706 10.3658 19.4772 11.6916 22.6854C11.7855 22.913 11.8884 23.136 12.0086 23.4117Z"
      fill="white"
    />
  </svg>
);

Logo.displayName = "Logo";

import { useState } from "react";
import Link from "next/link";

import { Button, clsx } from "@mantine/core";

import Authorize from "../auth/Authorize";

import { Logo } from "./icons/Logo";
import { HamburgerMenuButton } from "./HamburgerMenuButton";
import { type IMenuItems } from "./SideNavigation";

interface IMobileMenuProps {
  menuItems: IMenuItems[];
  menuItemsBottom: IMenuItems[];
  logoutTransString?: string;
  logoutFN?: () => void;
  linkLogo?: string;
  "data-testid"?: string;
}

export const MobileMenu = ({
  menuItems,
  menuItemsBottom,
  logoutTransString,
  linkLogo,
  logoutFN,
  "data-testid": dataTestId,
}: IMobileMenuProps) => {
  const [menuState, setMenuState] = useState(false);

  const menuClasses = clsx(
    "w-full min-h-screen h-max bg-navyBlue overscroll-contain z-50 relative sticky",
    {
      "transform -translate-y-[10000px]": !menuState,
    },
  );

  return (
    <div
      className="absolute bottom-0 left-0 right-0 top-0 h-[153px] w-full min-w-[300px] pt-16"
      data-testid={dataTestId}
      data-component="mobileMenu"
    >
      <div className="flex items-center justify-between bg-nav-gradient-mobile px-8 py-9">
        <Link
          href={linkLogo ? linkLogo : "/"}
          className="text-[33px] leading-[0]"
          aria-label="Homepage link"
        >
          <Logo />
        </Link>
        <HamburgerMenuButton
          setMenuState={() => setMenuState(!menuState)}
          menuState={menuState}
          data-testid={dataTestId && `hamburgerMenuButton-${dataTestId}`}
        />
      </div>
      <div className={menuClasses}>
        <nav className="text-left">
          <ul className="m-0 h-full list-none pl-0">
            <Authorize requiredPermissions={["JOB_SHOW_LIST"]}>
              {menuItems.map(({ title, link, label, flag }, index) => {
                if (flag !== undefined && !flag) return;

                return (
                  <li
                    key={index}
                    className="border-0 border-b border-solid border-lightBlue px-8 py-4"
                    onClick={() => setMenuState(false)}
                  >
                    <Link
                      href={link}
                      className="font-medium text-white no-underline"
                      aria-label={label ? label : `menu-link-${title}`}
                    >
                      {title}
                    </Link>
                  </li>
                );
              })}
            </Authorize>
            <Authorize requiredPermissions={["JOB_SHOW_LIST"]}>
              {menuItemsBottom.map(({ title, link, label, flag }, index) => {
                if (flag !== undefined && !flag) return;

                return (
                  <li
                    key={index}
                    className="border-0 border-b border-solid border-lightBlue px-8 py-4"
                    onClick={() => setMenuState(false)}
                  >
                    <Link
                      href={link}
                      className="font-medium text-white no-underline"
                      aria-label={label ? label : `menu-link-${title}`}
                    >
                      {title}
                    </Link>
                  </li>
                );
              })}
            </Authorize>
            <li
              className="mt-6 px-8 py-4 text-sm"
              onClick={logoutFN}
              data-testid="logout-button"
            >
              <Button
                className="w-full bg-white text-black no-underline hover:bg-white"
                name="logout"
                aria-label="logout button"
              >
                {logoutTransString ? logoutTransString : "Logout"}
              </Button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

const apiRoutes = {
  login: "/token/login-check",
  forgotPassword: "/password-reset-requests",
  resetPassword: "/user/password",
  refreshToken: "/token/refresh",
  job: {
    createSettings: "/jobs/create-settings",
    countContractorReach: "/jobs/count-contractor-reach",
    get: "/jobs/{id}",
    update: "/jobs/{id}",
    getBySlug: "/jobs/by-slug/{slug}",
    create: "/jobs/create",
    lock: "/jobs/{jobId}/lock",
    mutualConsentDispute: "/matches/{matchId}/dispute",
    revoke: "/jobs/{jobId}/revoke",
    getRevokeInfo: "/jobs/{jobId}/revoke",
    reopen: "/jobs/{jobId}/reopen",
    contractorReach: "/jobs/{jobId}/contractor-reach",
    workIntent: "/work-intents/{workIntentId}",
    workIntents: "/jobs/{jobId}/work-intents?status={status}",
    acceptWorkIntent: "/work-intents/accept",
    declineWorkIntents: "/work-intents/decline",
    matches: "/jobs/{jobId}/matches?state={state}",
    cancelMatch: "/matches/cancel",
    claim: {
      accept: "/matches/{matchId}/accept-claim",
      dispute: "/matches/{matchId}/dispute-claim",
    },
    getCancelMatchInfo: "/matches/{matchId}/cancel",
    registerNoShow: "/matches/{matchId}/register-no-show",
    getNoShowInfo: "/matches/{matchId}/register-no-show",
    declineDeclaration: "/declarations/{declarationId}/decline",
    acceptDeclaration: "/declarations/{declarationId}/accept",
    acceptAllDeclarations: "/declarations/accept",
    editDeclaration: "/declarations/{declarationId}/counter-offer",
    dismissNotifications: "/jobs/{jobId}/dismiss-notifications",
    downloadModelAgreement: "/jobs/{jobId}/model-agreements/{contractorId}",
    release: "/jobs/{jobId}/release",
    contractor: {
      skills: "/jobs/{jobId}/skill-ratings/{contractorId}",
    },
  },
  jobs: {
    get: "/jobs",
    export: "/jobs/export",
    countNotifications: "/jobs/count-notifications",
  },
  calendar: {
    period: "/jobs/calendar",
    details: "/jobs/{jobId}/calendar",
  },
  referenceNumbers: {
    get: "/client/reference-number",
    create: "/client/reference-number",
    delete: "/client/reference-number",
    update: "/client/reference-number",
  },
  locations: {
    get: "/client/locations",
    create: "/client/locations",
    delete: "/client/locations",
    update: "/client/locations",
    locationPredictions: "/api/locations",
  },
  contacts: {
    get: "/client/contacts",
    create: "/client/contacts",
    delete: "/client/contacts",
    update: "/client/contacts",
  },
  templates: {
    getByTitle: "/job-templates/by-title",
    get: "/job-templates",
    create: "/job-templates",
    delete: "/job-templates",
    update: "/job-templates",
  },
  favoriteContractors: {
    get: "/favorite-contractors?offset={offset}&limit={limit}&q={q}",
    update: "/favorite-contractors/{contractorId}",
  },
  blockedContractors: {
    get: "/blocked-contractors?offset={offset}&limit={limit}&q={q}",
    update: "/blocked-contractors/{contractorId}",
  },
  contractor: {
    get: "/contractors/{contractorId}/profile",
    report: {
      options: "/contractors/report",
      create: "/contractors/report",
    },
    skills: "/contractors/{contractorId}/skills",
  },
  client: {
    get: "/client",
    update: "/client",
    image: {
      update: "/client/image",
      delete: "/client/image",
    },
    coverPhoto: {
      update: "/client/background-image",
      delete: "/client/background-image",
    },
    register: "/client/register",
    verify: "/client/verify-email",
    reVerify: "/client/reverify",
    businessLookup: {
      default: "/client/business-lookup?query={search}&city={city}&page={page}",
      belgium: "/client/business-lookup?query={search}&page={page}",
    },
    fallbackBusinessLookup:
      "/client/business-lookup/{cocNumber}/{branchNumber}",
  },
  apiKeys: {
    getList: "/client/api-keys",
    update: "/client/api-keys",
    create: "/client/api-keys",
    delete: "/client/api-keys",
  },
  addressLookup: "/address/lookup/{postalCode}/{houseNumber}",
  addressLookupAlternative:
    "/address/lookup/{postalCode}/{houseNumber}/{street}",
  workspaces: {
    get: "/workspaces",
    create: "/workspaces",
    delete: "/workspaces",
    workspace: "/workspaces/{workspaceId}",
    update: "/workspaces/{workspaceId}",
    users: {
      get: "/workspaces/{workspaceId}/users",
    },
    whitelists: {
      locations: {
        get: "/workspaces/{workspaceId}/whitelists/locations",
        create: "/workspaces/{workspaceId}/whitelists/locations",
        delete: "/workspaces/{workspaceId}/whitelists/locations/{locationId}",
      },
      referenceNumbers: {
        get: "/workspaces/{workspaceId}/whitelists/reference-numbers",
        create: "/workspaces/{workspaceId}/whitelists/reference-numbers",
        delete:
          "/workspaces/{workspaceId}/whitelists/reference-numbers/{referenceNumberId}",
      },
    },
    nolists: {
      locations: {
        get: "/workspaces/{workspaceId}/nolists/locations",
      },
      referenceNumbers: {
        get: "/workspaces/{workspaceId}/nolists/reference-numbers",
      },
      contacts: {
        get: "/workspaces/{workspaceId}/nolists/contacts",
      },
    },
  },
  user: {
    get: "/user",
  },
  notificationPreferences: {
    get: "/user/notification-preferences",
    update: "/user/notification-preferences",
  },
  password: {
    update: "/user/password",
  },
  emailAddress: {
    update: "/user/email-address",
    confirm: "/user/email-address",
  },
  phoneNumber: {
    update: "/user/phone-number",
  },
  language: {
    get: "/locale-preferences",
    update: "/users/{userId}/locale-preferences",
  },
  users: {
    get: "/users",
    create: "/users",
    delete: "/users",
    update: "/users",
  },
  permissions: {
    get: "/permissions",
  },
  reviews: {
    get: "/client/reviews?sort-by={sort}&p={page}",
  },
  labels: {
    create: "/client/favorite-tags",
    get: "/client/favorite-tags",
    delete: "/client/favorite-tags/{labelUuid}",
    contractor: "/favorite-contractors/{contractorId}/tags",
  },
  settings: {
    count: "/client/settings-count",
  },
};

export default apiRoutes;

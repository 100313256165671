import React, { type ReactNode } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { UnstyledButton } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";

import Authorize from "../auth/Authorize";

import { Logo } from "./icons/Logo";

export interface IMenuItems {
  title: string;
  link: string;
  icon: ReactNode;
  label?: string;
  pathName: string;
  flag?: boolean;
}

interface ISideNavigation {
  menuItems: IMenuItems[];
  menuItemsBottom: IMenuItems[];
  logoutFN?: () => void;
  logoutTransString?: string;
  linkLogo?: string;
  "data-testid"?: string;
  currentPath: string;
}

export const SideNavigation = ({
  menuItems,
  menuItemsBottom,
  logoutFN,
  logoutTransString,
  linkLogo,
  currentPath,
  ...props
}: ISideNavigation) => {
  const router = useRouter();
  const link = router.isReady
    ? `/${String(router?.query?.region) || "nl"}/${
        String(router?.query?.lang) || "nl-NL"
      }/gigs?tab=open`
    : "/nl/nl-NL/gigs?tab=open";

  return (
    <div
      data-component="sideNavigation"
      className={`${"min-[769px]:pt-8"} fixed bottom-0 left-0 right-0 top-0 z-10 flex w-[8.5rem] flex-col overflow-auto bg-nav-gradient`}
      {...props}
    >
      <nav className="flex h-full flex-col">
        {/* Sidebar Header */}
        <div className="my-12 flex flex-col items-center justify-center">
          <Link
            href={linkLogo ? linkLogo : link}
            className="mb-0 text-5xl"
            aria-label="Link homepage"
          >
            <Logo />
          </Link>
        </div>
        {/* Sidebar Navigation */}
        <Authorize requiredPermissions={["JOB_SHOW_LIST"]}>
          <ul className="m-0 flex-1 p-0">
            {menuItems.map(
              ({ title, link, label, icon, pathName, flag }, index) => {
                if (flag !== undefined && !flag) return;

                const regEx = new RegExp(
                  pathName.split("?")[0].split("/")[3]?.toLowerCase(),
                );
                let filteredPathName = currentPath.toLowerCase();

                if (
                  !currentPath.toLocaleLowerCase().includes("[region]") &&
                  !currentPath.toLocaleLowerCase().includes("[lang]")
                )
                  filteredPathName = currentPath.toLowerCase().split("/")[3];

                return (
                  <li
                    className={`flex w-full items-center justify-center border-0 border-l-[3px] border-solid border-transparent hover:cursor-pointer hover:border-primary hover:bg-white/[0.15] hover:text-white ${
                      regEx.test(filteredPathName)
                        ? "border-0 border-l-[3px] !border-solid border-primary bg-white/[0.15] text-white"
                        : ""
                    }`}
                    key={index}
                  >
                    <Link
                      href={link}
                      aria-label={label ? label : `menu-link-${title}`}
                      className="flex w-full flex-col items-center justify-center gap-2 py-3 text-3xl text-white no-underline hover:no-underline"
                    >
                      {icon}
                      <span className="text-xs">{title}</span>
                    </Link>
                  </li>
                );
              },
            )}
          </ul>
        </Authorize>
        <ul className="m-0 p-0">
          {menuItemsBottom.map(
            ({ title, link, label, icon, pathName, flag }, index) => {
              if (flag !== undefined && !flag) return;

              const regEx = new RegExp(
                pathName.split("?")[0].split("/")[3].toLowerCase(),
              );
              let filteredPathName = currentPath.toLowerCase();

              if (
                !currentPath.toLocaleLowerCase().includes("[region]") &&
                !currentPath.toLocaleLowerCase().includes("[lang]")
              )
                filteredPathName = currentPath.toLowerCase().split("/")[3];

              return (
                <li
                  className={`flex w-full items-center justify-center border-0 border-l-[3px] border-solid border-transparent hover:cursor-pointer hover:border-primary hover:bg-white/[0.15] hover:text-white ${
                    regEx.test(filteredPathName)
                      ? "border-0 border-l-[3px] !border-solid border-primary bg-white/[0.15] text-white"
                      : ""
                  }`}
                  key={index}
                >
                  <Link
                    href={link}
                    aria-label={label ? label : `menu-link-${title}`}
                    className="flex w-full flex-col items-center justify-center gap-2 py-3 text-3xl text-white no-underline hover:no-underline"
                  >
                    {icon}
                    <span className="text-xs">{title}</span>
                  </Link>
                </li>
              );
            },
          )}

          <li
            className="flex w-full items-center justify-center border-0 border-l-[3px] border-solid border-transparent py-3 hover:cursor-pointer hover:border-primary hover:bg-primary/[0.15] hover:text-white"
            data-testid="logout-button"
            onClick={logoutFN}
          >
            <UnstyledButton className="flex flex-col items-center justify-center gap-2 text-xs text-white">
              <IconLogout width={36} height={36} stroke={1} />
              {logoutTransString ? logoutTransString : "Logout"}
            </UnstyledButton>
          </li>
        </ul>
      </nav>
    </div>
  );
};

SideNavigation.displayName = "SideNavigation";

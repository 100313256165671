import { forwardRef } from "react";

import {
  Select as MantineSelect,
  type SelectProps as MantineSelectProps,
} from "@mantine/core";

import { tailwindConfigColors } from "./utils/colors";
import { mergeClassNames } from "./utils/mergeClassNames";

export interface ISelect extends Omit<MantineSelectProps, "radius"> {
  "data-testid"?: string;
  ariaLabel?: string;
}

export const Select = forwardRef<HTMLInputElement, ISelect>(
  ({ classNames, searchable = true, clearable = false, ...props }, ref) => (
    <MantineSelect
      data-component="select"
      styles={() => ({
        rightSection: {
          pointerEvents: "none",
          color: "black",
        },
        item: {
          "&[data-selected]": {
            backgroundColor: "white",
            color: "black",
          },
          "&[data-selected]:hover": {
            backgroundColor: tailwindConfigColors.gray[50],
          },
          "&[data-hovered]": {
            backgroundColor: tailwindConfigColors.gray[50],
          },
        },
      })}
      classNames={mergeClassNames(
        {
          root: "leading-none",
          item: "hover:focus:bg-gray-50",
          label: "!font-medium text-xs text-black mb-2",
          input: "bg-ultraLightgray text-sm",
          dropdownBody: "z-10",
          error: "font-base text-xs pt-2",
          rightSection: "[&>svg]:!text-gray-800",
        },
        classNames,
      )}
      searchable={searchable}
      clearable={clearable}
      hoverOnSearchChange
      radius={5}
      shadow="0px 4px 19px 0px rgba(124, 124, 124, 0.18)"
      maxDropdownHeight={176}
      ref={ref}
      {...props}
    />
  ),
);

Select.displayName = "Select";

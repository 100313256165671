import { type MantineThemeOverride } from "@mantine/core";

import { mantineConfigColors, mantineConfigPrimaryColor } from "./colors";

export const mantineThemeOverride: MantineThemeOverride = {
  colors: mantineConfigColors,
  primaryShade: 5,
  primaryColor: mantineConfigPrimaryColor,
  fontFamily: '"Gotham", sans-serif',
  cursorType: "pointer",
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.5rem",
  },
  components: {
    InputWrapper: {
      defaultProps: {
        inputWrapperOrder: ["label", "input", "description", "error"],
      },
      classNames: {
        label: "text-xs font-medium text-black flex items-center gap-1",
        description: "text-gray-800 text-[10px] font-normal",
        error: "text-[10px] font-normal text-red-500",
      },
    },
    Input: {
      classNames: {
        input:
          "h-10 bg-ultraLightgray border-gray-200 text-black placeholder:text-gray-800 disabled:bg-gray-100 disabled:border-gray-300",
      },
    },
  },
};

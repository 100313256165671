export const mergeClassNames = (
  classNames1: Record<string, string>,
  classNames2?: Record<string, string>,
) => {
  if (!classNames2) return classNames1;

  const classNames = { ...classNames1, ...classNames2 };

  Object.keys(classNames1)
    .filter((key) => classNames2[key])
    .forEach((key) => {
      classNames[key] = `${classNames1[key]} ${classNames2[key]}`;
    });

  return classNames;
};

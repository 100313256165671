import { forwardRef } from "react";

import { clsx, UnstyledButton } from "@mantine/core";

interface IHamburgerMenuButtonProps {
  setMenuState: () => void;
  menuState: boolean;
  "data-testid"?: string;
}

export const HamburgerMenuButton = forwardRef<
  HTMLButtonElement,
  IHamburgerMenuButtonProps
>(
  (
    {
      menuState,
      setMenuState,
      "data-testid": dataTestId,
    }: IHamburgerMenuButtonProps,
    ref,
  ) => {
    const defaultClasses = clsx("absolute h-[2px] bg-white duration-150");

    return (
      <UnstyledButton
        onClick={setMenuState}
        data-testid={dataTestId}
        className="relative h-4 w-6"
        name="hamburgerMenuButton"
        data-component="hamburgerMenuButton"
        aria-label="Menu button"
        ref={ref}
      >
        <span
          className={clsx(defaultClasses, "left-0 top-0 w-6", {
            "translation top-1/2 translate-y-[-50%] rotate-45 transform duration-150 ease-in":
              menuState,
          })}
        />
        <span
          className={clsx(
            defaultClasses,
            "right-0 top-1/2 w-4 translate-y-[-50%]",
            {
              "translation opacity-0 ease-in": menuState,
            },
          )}
        />
        <span
          className={clsx(defaultClasses, "bottom-0 left-0 w-6", {
            "translation top-1/2 translate-y-[-50%] -rotate-45 transform duration-150 ease-in":
              menuState,
          })}
        />
      </UnstyledButton>
    );
  },
);

HamburgerMenuButton.displayName = "HamburgerMenuButton";

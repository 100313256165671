export const configFonts = {
  "xs-fields": [
    "0.625rem",
    {
      lineHeight: "1rem",
      fontWeight: "400",
    },
  ],
  "xs-field-titles": [
    "0.75rem",
    {
      lineHeight: "1rem",
      fontWeight: "700",
    },
  ],
  xs: [
    "0.75rem",
    {
      lineHeight: "1rem",
      fontWeight: "400",
    },
  ],
  sm: [
    "0.875rem",
    {
      lineHeight: "1.25rem",
      fontWeight: "400",
    },
  ],
  base: [
    "1rem",
    {
      lineHeight: "1.5rem",
      fontWeight: "400",
    },
  ],
  lg: [
    "1.125rem",
    {
      lineHeight: "1.75rem",
      fontWeight: "700",
    },
  ],
  xl: [
    "1.5rem",
    {
      lineHeight: "2rem",
      fontWeight: "500",
    },
  ],
  "2xl": [
    "1.5rem",
    {
      lineHeight: "2rem",
      fontWeight: "700",
    },
  ],
  "3xl": [
    "1.875rem",
    {
      lineHeight: "2.25rem",
      fontWeight: "700",
    },
  ],
};

export const tailwindConfigFonts = configFonts;

import React from "react";
import { useParams, useRouter } from "next/navigation";
import { useTranslation } from "next-i18next";

import { getPageRoute } from "@/utils/constants/pageRoutes";
import { type ILanguage, type translationKey } from "@/utils/i18n/languages";

import { Select } from "./Select";

export type Language = {
  id: string;
  i18n: string;
  serverKey: string;
  enabled?: boolean;
  translationKey: translationKey;
  supportEmail: string;
  defaultLanguage: boolean;
};

interface ILanguageSwitch {
  label: string;
  defaultLanguage: Language | undefined;
  languages: Language[];
  className?: string;
  page: "register" | "login" | "forgotPassword";
  currentLanguage?: ILanguage;
}

export const LanguageSelect = ({
  currentLanguage,
  languages,
  defaultLanguage,
  page,
  label,
  className,
}: ILanguageSwitch) => {
  const { t } = useTranslation();

  const router = useRouter();
  const params = useParams<{ lang: string; region: string }>();

  const reformattedData = languages?.map((language: Language) => ({
    value: language.i18n,
    label: t(language.translationKey),
  }));

  return (
    <Select
      label={label}
      data-component="languageSelect"
      className={className}
      value={defaultLanguage?.i18n || currentLanguage?.i18n}
      data-testid="language-switch"
      data={reformattedData}
      searchable={false}
      placeholder={
        currentLanguage?.translationKey && t(currentLanguage?.translationKey)
      }
      onChange={(language) => {
        router.push(
          getPageRoute(page, params.region ?? "nl", language ?? "nl-NL"),
        );
      }}
    />
  );
};

LanguageSelect.displayName = "LanguageSelect";

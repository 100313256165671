import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, { message: status }) =>
        status !== "404" &&
        status !== "401" &&
        process?.env?.NODE_ENV !== "test",
    },
  },
});

/**
 * @deprecated use defaultQueryOptions from utils/api/queryClient instead.
 */
const defaultQuerySettings = {
  retry: 3, // If a query failes retry it 3 times.
  staleTime: 5 * 60 * 1000, // Store fetched data for 5 minutes.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  select: (res: { data: any }) => res.data, // When using Axios the response data is wrapped in an axios object. This function gives us only the actual response data as a result from useQuery.
};

const defaultQueryOptions = <T>() => ({
  retry: 3, // If a query failes retry it 3 times.
  staleTime: 5 * 60 * 1000, // Store fetched data for 5 minutes.
  select: (res: { data: T }) => res.data, // When using Axios the response data is wrapped in an axios object. This function gives us only the actual response data as a result from useQuery.
});

export { defaultQuerySettings, queryClient, defaultQueryOptions };

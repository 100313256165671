import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { appWithTranslation } from "next-i18next";

// eslint-disable-next-line import/no-unresolved
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { withLDProvider } from "launchdarkly-react-client-sdk";

import { AuthProvider } from "@/components/auth/AuthProvider";
import { MantineProvider } from "@/components/common/MantineProvider";
import { ToastProvider } from "@/components/ui/toast/ToastProvider";
import { GA_ANALYTICS_MEASUREMENT_ID } from "@/utils/analytics/gtag";
import {
  getMarketingCookieConsent,
  getStatisticsCookieConsent,
} from "@/utils/cookies/cookieConsentRepository";

import "@/utils/scripts/wdyr";
// TODO: needs to be changed to this for Xplosive in order to ditch all SCSS files
import "../styles/globals.css";

import { queryClient } from "../services/queryClient";

import nextI18nextConfig from "@/next-i18next.config";

const hotjarEnabled = process.env.NEXT_PUBLIC_HOTJAR_ENABLED === "true";

import EppoRandomizationProvider from "@/components/eppo/EppoRandomizationProvider";

function MyApp({ Component, pageProps }: AppProps) {
  const isStatisticsCookiesAllowed = getStatisticsCookieConsent();
  const isMarketingCookiesAllowed = getMarketingCookieConsent();

  return (
    <>
      <Head>
        <title>YoungOnes</title>

        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <span id="CollectedForms-14493935" />
      <ToastProvider autoDelete>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <MantineProvider>
              {isStatisticsCookiesAllowed && (
                <Script
                  id="google-analytics"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer', '${GA_ANALYTICS_MEASUREMENT_ID}');
                  `,
                  }}
                />
              )}
              {isMarketingCookiesAllowed &&
                process.env.NODE_ENV !== "development" && (
                  <Script
                    id="hs-script-loader"
                    strategy="lazyOnload"
                    type="text/javascript"
                    async
                    defer
                    src="//js.hs-scripts.com/14493935.js"
                  />
                )}
              {isMarketingCookiesAllowed && hotjarEnabled && (
                <Script id="hotjar">
                  {`(function(h,o,t,j,a,r){
                      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                      h._hjSettings={hjid:4966637,hjsv:6};
                      a=o.getElementsByTagName('head')[0];
                      r=o.createElement('script');r.async=1;
                      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                      a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
                </Script>
              )}
              <EppoRandomizationProvider>
                <Component {...pageProps}></Component>
              </EppoRandomizationProvider>
              <SpeedInsights />
              <Analytics />
            </MantineProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </AuthProvider>
      </ToastProvider>
    </>
  );
}

export default withLDProvider({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID!,
  context: {
    kind: "user",
    key: "guest",
  },
})(appWithTranslation(MyApp, nextI18nextConfig));

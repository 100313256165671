import React, { forwardRef } from "react";

import {
  TextInput as MantineTextInput,
  type TextInputProps as MantineTextInputProps,
} from "@mantine/core";
import clsx from "clsx";

import formatIcon from "../utils/formatIcon";
import { mergeClassNames } from "../utils/mergeClassNames";

import { TextInputContext, TextInputProvider } from "./TextInputContext";
interface ITextInput extends Omit<MantineTextInputProps, "radius" | "size"> {
  className?: string;
  iconWidth?: number;
  rightSectionWidth?: number;
  leftSectionWidth?: number;
  leftSection?: React.ReactNode;
  enablePasswordManagerAutofill?: boolean;
  showRightSection?: boolean;
  "data-testid"?: string;
}

export const TextInput = forwardRef<HTMLInputElement, ITextInput>(
  (
    {
      icon,
      classNames,
      iconWidth = 36,
      leftSection,
      leftSectionWidth: leftSectionWidthProp,
      rightSectionWidth = 48,
      error: errorProp,
      enablePasswordManagerAutofill = false,
      showRightSection = true,
      rightSection,
      ...props
    },
    ref,
  ) => {
    const leftSectionIsDropdown =
      // @ts-expect-error
      leftSection?.type?.displayName === "TextInputSelect";

    const leftSectionWidth =
      leftSectionWidthProp || leftSectionIsDropdown ? 72 : 48;

    let combinedIconWidth = 12; // default padding when no icon or leftSection is present
    if (icon && leftSection) combinedIconWidth = iconWidth + leftSectionWidth;
    else if (!icon && leftSection) combinedIconWidth = leftSectionWidth;
    else if (icon && !leftSection) combinedIconWidth = iconWidth;

    const error =
      errorProp !== "" && typeof errorProp === "string" ? (
        <div>{errorProp}</div>
      ) : (
        errorProp
      );

    return (
      <TextInputProvider>
        <TextInputContext.Consumer>
          {(ctx) => {
            const selectError =
              typeof ctx?.state?.selectError === "string" ? (
                <div>{ctx?.state?.selectError}</div>
              ) : (
                ctx?.state?.selectError
              );

            const combinedError = () => {
              if (!error && !selectError) return null;

              return (
                <>
                  {selectError}
                  {error}
                </>
              );
            };

            return (
              <MantineTextInput
                error={combinedError()}
                classNames={mergeClassNames(
                  {
                    root: "leading-none",
                    // icon is the left section
                    icon: clsx("text-black text-sm font-medium", {
                      "pointer-events-auto justify-start":
                        leftSectionIsDropdown,
                    }),
                    rightSection: clsx("text-black text-sm font-medium", {
                      "!text-red-500": error,
                    }),
                    label: "!font-medium text-xs text-black mb-2",
                    input: "bg-ultraLightgray border-gray-200 text-sm",
                    error: "font-base text-xs pt-2",
                  },
                  classNames,
                )}
                rightSectionWidth={rightSectionWidth}
                icon={
                  <>
                    {formatIcon(icon, {
                      strokeWidth: "1",
                      className: "text-black w-5 h-5",
                    })}
                    {leftSection}
                  </>
                }
                iconWidth={combinedIconWidth}
                ref={ref}
                data-lpignore={
                  enablePasswordManagerAutofill ? undefined : "true"
                } // lastpass
                data-1p-ignore={
                  enablePasswordManagerAutofill ? undefined : "true"
                } // 1Password
                rightSection={showRightSection && rightSection}
                {...props}
              />
            );
          }}
        </TextInputContext.Consumer>
      </TextInputProvider>
    );
  },
);

TextInput.displayName = "TextInput";

import { forwardRef } from "react";

import {
  Checkbox as MantineCheckbox,
  type CheckboxProps as MantineCheckboxProps,
} from "@mantine/core";

export interface ICheckbox
  extends Omit<MantineCheckboxProps, "radius" | "size" | "labelPosition"> {
  size?: "xs" | "sm";
  /**
   * @deprecated Can be removed entirely.
   */
  smallerText?: boolean;
  /**
   * @deprecated Can be removed entirely.
   */
  boldText?: boolean;
  /**
   * @deprecated Can be removed entirely.
   */
  mediumBoldText?: boolean;
  "data-testid"?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, ICheckbox>(
  ({ size = "sm", disabled = false, ...props }, ref) => (
    <MantineCheckbox
      classNames={{
        input:
          "[&:not([disabled])]:checked:bg-lightBlue [&:not([disabled])]:checked:border-lightBlue border-gray-800 disabled:bg-gray-100 disabled:border-gray-300 disabled:[&[checked]]:border-gray-100",
        icon: disabled ? "!text-gray-300" : "",
        description: "!text-gray-800",
        error: "font-base text-xs pt-2",
        label: `text-sm`,
      }}
      labelPosition="right"
      data-component="checkbox"
      size={size}
      radius={1}
      disabled={disabled}
      ref={ref}
      {...props}
    />
  ),
);

Checkbox.displayName = "Checkbox";

import axios, { type AxiosRequestConfig } from "axios";

import apiRoutes from "@/utils/constants/apiRoutes";
import { getRegionBySlug } from "@/utils/regions/regionsConfig";

import { type IUser } from "@/types";

interface IUserResponse {
  user: IUser;
}

export const getUser = async (
  token: string,
  impersonate: string | null,
  region: string,
) => {
  const currentRegion = getRegionBySlug(region);

  if (currentRegion === null) return;

  const axiosConfig: AxiosRequestConfig = {
    baseURL: currentRegion?.apiUrl,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  };

  if (impersonate)
    axiosConfig.params = { ...axiosConfig.params, _switch_user: impersonate };

  const response = await axios.get<IUserResponse>(apiRoutes.user.get, {
    ...axiosConfig,
  });

  const { user } = response.data;

  return user;
};

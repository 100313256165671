import * as React from "react";
import { type SVGProps } from "react";
export const IconFileExport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    {...props}
  >
    <path
      d="M11.4443 0.694092V5.13854C11.4443 5.43322 11.5614 5.71584 11.7698 5.92421C11.9781 6.13258 12.2608 6.24965 12.5554 6.24965H16.9999"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7777 20.6941H3.66656C3.07719 20.6941 2.51196 20.46 2.09521 20.0432C1.67846 19.6265 1.44434 19.0612 1.44434 18.4719V2.91631C1.44434 2.32694 1.67846 1.76171 2.09521 1.34497C2.51196 0.928218 3.07719 0.694092 3.66656 0.694092H11.4443L16.9999 6.24965V18.4719C16.9999 19.0612 16.7658 19.6265 16.349 20.0432C15.9323 20.46 15.367 20.6941 14.7777 20.6941Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.22217 16.2497V9.58301"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.44434 13.4719L9.22211 16.2497L11.9999 13.4719"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { type HTMLAttributes } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Trans } from "next-i18next";

import { format, parseISO } from "date-fns";

import { getPageRoute } from "@/utils/constants/pageRoutes";
import { localeFormatter } from "@/utils/formatters/langFormatter";

import { FavoriteBadge } from "../gig/FavoriteBadge";
import { TableCell } from "../ui/TableCell";
import { TableRow } from "../ui/TableRow";

import { GigsBadge } from "./GigsBadge";

import { type IJob } from "@/types";

interface IGigRow extends HTMLAttributes<HTMLButtonElement> {
  job?: IJob;
}

export const GigRow = ({ job }: IGigRow) => {
  const router = useRouter();
  const region = router?.query?.region || "nl";
  const lang = router?.query?.lang || "nl-NL";

  if (!job) return null;

  const parsedStartDate = format(parseISO(job.startsAt), "EEEE dd MMM yyyy", {
    locale: localeFormatter(String(router?.query?.lang)),
  });
  const parsedTimeStart = format(parseISO(job.startsAt), "H:mm");
  const parsedTimeEnd = format(parseISO(job.endsAt), "H:mm");
  const houseNumber =
    job?.address?.houseNumber && ` ${job?.address?.houseNumber}`;
  const city = job?.address?.city && ` (${job.address.city})`;
  const street = job.address.street && job.address.street;

  return (
    <TableRow>
      <TableCell>
        <Link
          className="relative grid min-h-[138px] w-full grid-cols-2 items-center gap-2 hover:no-underline xl:grid-cols-3"
          data-testid={`gig-row-${job.id}`}
          data-component="gigRow"
          href={getPageRoute(
            "gigDetail",
            region as string,
            lang as string,
          ).replace("{gigSlug}", job.slug)}
        >
          <div className="col-span-2 lg:col-span-2">
            <div className="flex w-full flex-col items-baseline justify-between gap-1 md:items-start">
              <h2 className="grid gap-1 text-start text-lg font-bold text-black">
                <span>
                  <FavoriteBadge isFavorite={job?.isExclusivelyForFavorites} />
                </span>
                {job.title}
              </h2>
              <div className="w-full text-xs font-normal text-gray-700">
                <div className="flex w-full flex-row justify-start space-x-2">
                  <span>{parsedStartDate}</span>
                  <span>|</span>
                  <span>{`${parsedTimeStart} - ${parsedTimeEnd}`}</span>
                  <span>|</span>
                  <span>
                    {street}
                    {houseNumber}
                    {city}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 grid grid-cols-2 items-center justify-end xl:col-span-1 xl:grid-cols-2">
            <span className="whitespace-nowrap text-xs font-medium text-black xl:flex xl:items-center xl:justify-end">
              <Trans
                i18nKey="job.numberOfContractors"
                values={{
                  count: job.numberOfContractors,
                  nrOfMatches: job.countMatches,
                }}
              ></Trans>
            </span>
            <div className="flex justify-end">
              <GigsBadge job={job} />
            </div>
          </div>
        </Link>
      </TableCell>
    </TableRow>
  );
};

import { forwardRef, useEffect, useImperativeHandle } from "react";

import { RichTextEditor } from "@mantine/tiptap";
import {
  IconBold,
  IconClearFormatting,
  IconItalic,
  IconList,
  IconListNumbers,
  IconUnderline,
} from "@tabler/icons-react";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Underline } from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";

import { cn } from "./utils/cn";

export interface IWysiwygEditorImperativeHandle {
  resetContent: (newContent: string) => void;
}
interface IWysiwygEditor {
  id: string;
  content?: string;
  placeholder: string;
  forceRefresh?: boolean;
  readOnly?: boolean;
  onChange?: (v: string) => void;
  error?: string;
  className?: string;
  formName?: string;
}

const BoldIcon = () => <IconBold size={18} stroke={1.5} />;
const ItalicIcon = () => <IconItalic size={18} stroke={1.5} />;
const UnderlineIcon = () => <IconUnderline size={18} stroke={1.5} />;
const ClearFormattingIcon = () => (
  <IconClearFormatting size={18} stroke={1.5} />
);
const ListIcon = () => <IconList size={18} stroke={1.5} />;
const ListNumbersIcon = () => <IconListNumbers size={18} stroke={1.5} />;

export const WysiwygEditor = forwardRef(
  (
    {
      id,
      content,
      className,
      placeholder,
      onChange,
      readOnly,
      error,
    }: IWysiwygEditor,
    ref,
  ) => {
    const editor = useEditor({
      extensions: [
        StarterKit,
        Underline,
        Placeholder.configure({
          placeholder: placeholder,
        }),
      ],
      editorProps: {
        attributes: {
          class: "min-h-[150px] bg-ultraLightgray text-sm",
        },
        transformPastedHTML(html: string) {
          const newHtml = html.replace(
            /(<(?!\/?(strong|i|b|u|ul|ol|li|p|br)\b)[^>]*>)/gi,
            " ",
          );
          return newHtml;
        },
      },
      content: content,
      editable: readOnly ? !readOnly : true,
      onUpdate({ editor }) {
        onChange &&
          onChange(
            editor
              .getHTML()
              .replaceAll(
                /<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi,
                "<li>$1<$2$3>",
              ),
          );
      },

      injectCSS: false,
    });

    useEffect(() => {
      if (!editor) return;
      if (content === null) editor.commands.setContent("");
    }, [content, editor]);

    useEffect(() => {
      if (!editor) return;
      editor.setEditable(readOnly ? !readOnly : true);
    }, [readOnly, editor]);

    useImperativeHandle(
      ref,
      () => ({
        resetContent(newContent: string) {
          editor?.commands?.setContent(newContent);
        },
      }),
      [editor],
    );

    if (typeof window === "undefined" || typeof document === "undefined")
      return <></>;

    return (
      <>
        <RichTextEditor
          data-component="wysiwygEditor"
          data-testid={id}
          id={id}
          editor={editor}
          className={cn(
            "border-gray-200 text-sm",
            className,
            error ? "!border-1 !border !border-solid !border-red-600" : "",
          )}
        >
          <RichTextEditor.Toolbar
            sticky
            stickyOffset={-48}
            className="border-gray-200"
          >
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold icon={BoldIcon} />
              <RichTextEditor.Italic icon={ItalicIcon} />
              <RichTextEditor.Underline icon={UnderlineIcon} />
              <RichTextEditor.ClearFormatting icon={ClearFormattingIcon} />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.BulletList icon={ListIcon} />
              <RichTextEditor.OrderedList icon={ListNumbersIcon} />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>
          <RichTextEditor.Content />
        </RichTextEditor>
        {error && (
          <span className="font-base text-xs text-red-600">{error}</span>
        )}
      </>
    );
  },
);

WysiwygEditor.displayName = "WysiwygEditor";

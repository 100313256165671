import * as React from "react";
import { type SVGProps } from "react";
export const IconCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Check">
      <path
        id="Vector"
        d="M1 7L6.33333 12.6471L17 1.35294"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

import * as React from "react";
import { type SVGProps } from "react";
export const IconThumbsDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.46954 5.56368V1.06504C3.46954 0.915899 3.41029 0.772868 3.30483 0.667411C3.19938 0.561953 3.05635 0.502708 2.90721 0.502708H1.78255C1.63341 0.502708 1.49038 0.561953 1.38492 0.667411C1.27946 0.772868 1.22021 0.915899 1.22021 1.06504V5.00135C1.22021 5.15049 1.27946 5.29352 1.38492 5.39898C1.49038 5.50444 1.63341 5.56368 1.78255 5.56368H3.46954ZM3.46954 5.56368C4.0661 5.56368 4.63822 5.80067 5.06005 6.2225C5.48188 6.64433 5.71886 7.21645 5.71886 7.81301V8.37534C5.71886 8.67362 5.83735 8.95968 6.04827 9.17059C6.25918 9.38151 6.54524 9.5 6.84352 9.5C7.1418 9.5 7.42786 9.38151 7.63878 9.17059C7.84969 8.95968 7.96818 8.67362 7.96818 8.37534V5.56368H9.65518C9.95345 5.56368 10.2395 5.44519 10.4504 5.23428C10.6613 5.02336 10.7798 4.7373 10.7798 4.43902L10.2175 1.62737C10.1366 1.28239 9.98322 0.986175 9.78038 0.783331C9.57753 0.580487 9.33624 0.482001 9.09284 0.502708H5.15653C4.70911 0.502708 4.28002 0.680444 3.96365 0.996816C3.64727 1.31319 3.46954 1.74228 3.46954 2.1897"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

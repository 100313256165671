import { enGB, fr, nl, nlBE } from "date-fns/locale";

export const localeFormatter = (locale: string) => {
  switch (locale) {
    case "nl_NL":
      return nl;
    case "nl-NL":
      return nl;
    case "nl":
      return nl;
    case "fr_FR":
      return fr;
    case "fr-FR":
      return fr;
    case "fr":
      return fr;
    case "nl_BE":
      return nlBE;
    case "nl-BE":
      return nlBE;
    default:
      return enGB;
  }
};

import Image from "next/image";
import { useRouter } from "next/router";

import { getPageRoute } from "@/utils/constants/pageRoutes";
import { type IRegion } from "@/utils/regions/regionsConfig";
import { setStorageValue } from "@/utils/storage/storage";

interface IRegionSelector {
  title: string;
  regions: IRegion[];
  "data-testid"?: string;
}

export const RegionSelector = ({
  title,
  regions,
  ...props
}: IRegionSelector) => {
  const router = useRouter();

  return regions?.length ? (
    <div
      data-component="regionSelector"
      className="flex w-full flex-col items-center justify-center gap-4 break-words"
      {...props}
    >
      <h1 className="w-full text-2xl font-bold leading-10 md:text-3xl">
        {title}
      </h1>
      <ul
        className="mx-auto flex w-full max-w-[415px] list-none flex-col items-start justify-start pl-0 hover:cursor-pointer"
        data-testid="region-select"
      >
        {regions.map((region) => (
          <li
            className="border-1 mb-3.5 flex max-h-[70px] w-full max-w-[415px] rounded border border-solid border-gray-100 py-[11px] hover:border-primary"
            key={region.id}
            data-testid={`region-select-${region.id}`}
            onClick={() => {
              router.push(getPageRoute("login", region.slug, region.locale));
              setStorageValue("i18nextLng", region.locale);
            }}
          >
            <div className="flex h-full w-full flex-initial flex-row items-center justify-between px-[25px]  text-black no-underline">
              <>
                <Image
                  src={region.flag}
                  alt=""
                  className="max-h-12 max-w-12 flex-initial"
                  width={50}
                  height={50}
                />

                <span className="ml-6 w-4/5 flex-initial text-left align-top leading-none hover:underline">
                  {region.countryName}
                </span>
              </>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

RegionSelector.displayName = "RegionSelector";

import {
  DatePicker,
  type DatePickerBaseProps as MantineDatePickerBaseProps,
} from "@mantine/dates";
import clsx from "clsx";

interface ICalendarProps extends MantineDatePickerBaseProps {
  "data-testid"?: string;
}

export const onHoverCalendarClass = clsx(
  "before:content-[''] before:rounded before:absolute before:inset-0 before:bg-gradient-calendar before:top-0 before:left-0 before:right-0 before:bottom-0 before:opacity-0 before:transition-all before:duration-300 before:ease-in before:z-[-1]",
  "flex h-full w-full items-center justify-center z-[1] hover:text-white",
  "hover:before:opacity-100",
);

export const Calendar = (props: ICalendarProps) => (
  <DatePicker
    minDate={new Date()}
    data-component="calendar"
    hideOutsideDates
    type="multiple"
    weekendDays={[]}
    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
    getDayProps={() => ({
      className: "relative md:m-1.5 data-[selected]:text-black",
      renderDay: (day) => (
        <span className={onHoverCalendarClass}>{day.getDate()}</span>
      ),
    })}
    styles={{
      calendarHeader: {
        margin: "0 auto 1.5rem",
      },
    }}
    {...props}
  />
);

Calendar.displayName = "Calendar";

import { forwardRef } from "react";

import {
  DatePickerInput as MantineDatePickerInput,
  type DatePickerInputProps,
} from "@mantine/dates";

import { cn } from "./utils/cn";
import { onHoverCalendarClass } from ".";

interface IDatePickerInputProps extends DatePickerInputProps {
  "data-testid"?: string;
}

export const DatePickerInput = forwardRef<
  HTMLButtonElement,
  IDatePickerInputProps
>((props: IDatePickerInputProps, ref) => (
  <MantineDatePickerInput
    data-component="datePickerInput"
    ref={ref}
    classNames={{
      label: "!font-medium !text-xs mb-2",
      input: "bg-ultraLightgray h-10  min-h-[36px] text-sm",
      root: "leading-none",
      error: "font-base text-xs pt-2",
      placeholder: "text-gray-800",
    }}
    getDayProps={() => ({
      className: "relative data-[selected]:text-black",
      renderDay: (day) => (
        <span className={cn(onHoverCalendarClass, "text-black")}>
          {day.getDate()}
        </span>
      ),
    })}
    {...props}
  />
));

DatePickerInput.displayName = "DatePickerInput";

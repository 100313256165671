import * as React from "react";
import { type SVGProps } from "react";
export const IconThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 2831">
      <path
        id="Vector"
        d="M2.61765 4.93632V9.43496C2.61765 9.5841 2.56187 9.72713 2.46259 9.83259C2.3633 9.93805 2.22864 9.99729 2.08824 9.99729H1.02941C0.889003 9.99729 0.754345 9.93805 0.655061 9.83259C0.555777 9.72713 0.5 9.5841 0.5 9.43496V5.49865C0.5 5.34951 0.555777 5.20648 0.655061 5.10102C0.754345 4.99556 0.889003 4.93632 1.02941 4.93632H2.61765ZM2.61765 4.93632C3.17928 4.93632 3.71791 4.69933 4.11505 4.2775C4.51219 3.85567 4.73529 3.28355 4.73529 2.68699V2.12466C4.73529 1.82638 4.84685 1.54032 5.04542 1.32941C5.24398 1.11849 5.5133 1 5.79412 1C6.07494 1 6.34425 1.11849 6.54282 1.32941C6.74139 1.54032 6.85294 1.82638 6.85294 2.12466V4.93632H8.44118C8.72199 4.93632 8.99131 5.05481 9.18988 5.26572C9.38845 5.47664 9.5 5.7627 9.5 6.06098L8.97059 8.87263C8.89445 9.21761 8.75002 9.51382 8.55905 9.71667C8.36808 9.91951 8.14091 10.018 7.91177 9.99729H4.20588C3.78466 9.99729 3.38068 9.81956 3.08283 9.50318C2.78498 9.18681 2.61765 8.75772 2.61765 8.3103"
        stroke="CurrentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

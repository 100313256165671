import React, { type ButtonHTMLAttributes, forwardRef } from "react";

import clsx from "clsx";

import { cn } from "./utils/cn";
import formatIcon from "./utils/formatIcon";

const defaultButtonStyling =
  "justify-center group relative flex items-center gap-2 cursor-pointer border rounded py-2 px-5 text-sx md:text-sm md:font-medium font-medium hover:outline-none focus:outline-none disabled:cursor-not-allowed";

export const buttonVariants = {
  primary: clsx(
    defaultButtonStyling,
    "bg-primary hover:bg-turquoise-700 hover:fill-white border-transparent disabled:bg-turquoise-100 disabled:hover:bg-primary disabled:hover:bg-turquoise-100 disabled:text-gray-800 text-black",
  ),
  secondary: clsx(
    defaultButtonStyling,
    "bg-white disabled:hover:bg-transparent disabled:text-gray-800 disabled:hover:border-gray-200 disabled:hover:bg-white hover:bg-gray-50 hover:border-gray-800 hover:fill-white border-gray-200 border-solid text-black",
  ),
  danger: clsx(
    defaultButtonStyling,
    "bg-red-500 text-white border-transparent disabled:bg-red-100 disabled:hover:bg-red-100 hover:bg-red-700",
  ),
  link: clsx(
    defaultButtonStyling,
    "bg-transparent text-black border-transparent disabled:bg-transparent disabled:hover:bg-transparent hover:bg-gray-50 disabled:text-gray-800",
  ),
  outline: clsx(
    defaultButtonStyling,
    "bg-transparent disabled:hover:bg-transparent disabled:text-gray-800 disabled:hover:border-gray-200 disabled:hover:bg-white hover:bg-[rgba(255,255,255,0.1)] hover:border-gray-800 border-gray-200 border-solid text-white",
  ),
  search: clsx(
    defaultButtonStyling,
    "absolute right-0 top-0 h-full w-[50px] bg-transparent bg-no-repeat border-0",
  ),
  icon__primary: clsx(
    defaultButtonStyling,
    "button button__svg button__svg--edit button--icon !flex items-center justify-center",
  ),
  icon__danger: clsx(
    defaultButtonStyling,
    "button button__svg button--error !flex items-center justify-center",
  ),
} as const;

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof buttonVariants;
  ariaLabel?: string;
  isLoading?: boolean;
  block?: boolean;
  dataTestId?: string;
  icon?: React.ReactNode;
  iconRight?: boolean;
}

const LoadingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-component="button"
    data-testid="loading-svg-button"
    viewBox="0 0 256 256"
    {...props}
  >
    <line
      x1="128"
      y1="32"
      x2="128"
      y2="64"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    ></line>
    <line
      x1="195.9"
      y1="60.1"
      x2="173.3"
      y2="82.7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    ></line>
    <line
      x1="224"
      y1="128"
      x2="192"
      y2="128"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    ></line>
    <line
      x1="195.9"
      y1="195.9"
      x2="173.3"
      y2="173.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    ></line>
    <line
      x1="128"
      y1="224"
      x2="128"
      y2="192"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    ></line>
    <line
      x1="60.1"
      y1="195.9"
      x2="82.7"
      y2="173.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    ></line>
    <line
      x1="32"
      y1="128"
      x2="64"
      y2="128"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    ></line>
    <line
      x1="60.1"
      y1="60.1"
      x2="82.7"
      y2="82.7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    ></line>
  </svg>
);

export const Button = forwardRef<HTMLButtonElement, IButton>(
  (
    {
      type = "button",
      variant = "primary",
      disabled = false,
      role = "button",
      block = false,
      isLoading = false,
      icon: iconFromProps = null,
      iconRight = false,
      className,
      ariaLabel,
      onClick,
      children,
      dataTestId,
      ...props
    }: IButton,
    ref,
  ) => {
    const icon = isLoading ? (
      <LoadingIcon className="mr-2 animate-spin-slow stroke-current" />
    ) : (
      iconFromProps
    );

    const formattedIcon = formatIcon(icon, {
      className: clsx("h-[1.125rem] w-[1.125rem]", {
        "-mr-2": iconRight,
        "-ml-2": !iconRight,
      }),
    });

    return (
      <button
        type={type}
        role={role}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        className={cn(buttonVariants[variant], className, block && "w-full")}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {!iconRight && formattedIcon}
        {children}
        {iconRight && formattedIcon}
      </button>
    );
  },
);

Button.displayName = "Button";

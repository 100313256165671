import React, { type HTMLAttributes, type SVGAttributes } from "react";

import { type TablerIconsProps } from "@tabler/icons-react";
import clsx from "clsx";

const globalDefaultFormatAttributes: SVGAttributes<SVGElement> &
  HTMLAttributes<SVGElement> = {};

/**
 * Format icon with default attributes. Icon attributes will
 * always supersede default attributes which in term will supersede
 * global default attributes.
 */
export default function formatIcon(
  icon: React.ReactNode | undefined,
  defaultFormatAttributes:
    | (SVGAttributes<SVGElement> & HTMLAttributes<SVGElement>)
    | TablerIconsProps,
) {
  if (!React.isValidElement(icon)) return icon;

  return React.cloneElement(icon, {
    ...globalDefaultFormatAttributes,
    ...defaultFormatAttributes,
    ...icon.props,
    className: clsx(
      globalDefaultFormatAttributes.className,
      defaultFormatAttributes.className,
      icon.props.className,
    ),
  });
}

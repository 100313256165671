import { cn } from "./utils/cn";

interface ITableRow
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "className"> {
  children: React.ReactNode;
  className?: string;
  header?: boolean;
}

export const TableRow = ({ children, className, ...props }: ITableRow) => (
  <div
    data-component="tableRow"
    className={`${cn(
      "table-row border-x-0 border-y-0 border-t border-solid border-gray-200 xl:border-t xl:border-solid xl:border-gray-200",
      "hover:bg-ultraLightBlue",
      className,
    )}`}
    {...props}
  >
    {children}
  </div>
);

import { cn } from "./utils/cn";

interface IGenericSkeleton {
  className: string;
}

export const GenericSkeleton = ({ className }: IGenericSkeleton) => (
  <span
    data-component="genericSkeleton"
    className={cn(
      "inline-block h-full w-full rounded-md",
      "animate-pulse bg-gray-100",
      className,
    )}
  />
);

import * as React from "react";
import { type SVGProps } from "react";
export const IconStarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9656 0.852196L13.2213 5.42293C13.3782 5.74083 13.6815 5.96111 14.0324 6.01201L19.0762 6.74501C19.9602 6.87351 20.3127 7.95904 19.6736 8.5819L16.0236 12.1397C15.77 12.3871 15.654 12.7438 15.7141 13.093L16.5756 18.1168C16.7266 18.9966 15.8029 19.6675 15.0128 19.2524L10.5013 16.8807C10.1875 16.7159 9.81248 16.7159 9.49875 16.8807L4.98726 19.2524C4.1971 19.6679 3.27344 18.9966 3.42447 18.1168L4.28596 13.093C4.34604 12.7438 4.23006 12.3871 3.97641 12.1397L0.326406 8.5819C-0.31273 7.95862 0.0397965 6.87309 0.923406 6.74501L5.96766 6.01201C6.31851 5.96111 6.62181 5.74083 6.77868 5.42293L9.03442 0.852196C9.42908 0.0516077 10.5705 0.0516077 10.9656 0.852196Z"
      fill="currentColor"
    />
  </svg>
);

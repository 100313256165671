import { cn } from "../utils/cn";

interface AccordionProps {
  children?: React.ReactNode;
  className?: string;
}

export const AccordionWrapper = ({ children, className }: AccordionProps) => (
  <div className={cn(className, `flex flex-col gap-2`)}>{children}</div>
);

import React from "react";

import {
  ThemeIcon as MantineThemeIcon,
  type ThemeIconProps,
} from "@mantine/core";
import clsx from "clsx";

import { COLOR, THEME_ICON_STYLE_VARIANT } from "./utils/enums";
import formatIcon from "./utils/formatIcon";

export interface IThemeIcon extends Omit<ThemeIconProps, "children"> {
  title: string;
  styleVariant?:
    | "black"
    | "gray"
    | "pink"
    | "pinkWithBackground"
    | "turquoise"
    | "accentBlue"
    | "check"
    | "warning"
    | "error";
  onClick?: () => void;
  icon: React.ReactNode;
  "data-testid"?: string;
  adjustedSize?: string | number;
}

export const ThemeIcon = ({
  title,
  styleVariant = "black",
  className,
  icon,
  onClick,
  adjustedSize,
  ...props
}: IThemeIcon) => {
  const classNames = clsx(
    "bg-transparent",
    {
      "text-black": styleVariant === COLOR.BLACK,
      "text-gray-500": styleVariant === COLOR.GRAY,
      "text-pink": styleVariant === COLOR.PINK,
      "!bg-gray-50 text-pink child:p-10 w-[37px] h-[37px]":
        styleVariant === THEME_ICON_STYLE_VARIANT.PINK_WITH_BACKGROUND,
      "text-primary": styleVariant === COLOR.TURQUOISE,
      "text-accentBlue": styleVariant === COLOR.ACCENT_BLUE,
      "text-check": styleVariant === COLOR.CHECK,
      "text-warning": styleVariant === COLOR.WARNING,
      "text-red-500": styleVariant === COLOR.ERROR,
    },
    className,
  );

  return (
    <MantineThemeIcon
      data-component="themeIcon"
      title={title}
      className={classNames}
      onClick={onClick}
      {...props}
    >
      {formatIcon(icon, {
        strokeWidth: "1",
        size: adjustedSize ? adjustedSize : 20,
      })}
    </MantineThemeIcon>
  );
};

ThemeIcon.displayName = "ThemeIcon";

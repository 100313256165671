import { createContext, useContext } from "react";

import { type IClient, type IUser } from "@/types";

interface IAuthContext {
  token: string | null;
  user: IUser | null;
  client: IClient | null;
  isLoading: boolean;
  setToken: (token: string | null) => void;
  refreshToken: () => Promise<void>;
  impersonate: string | null;
  setImpersonate: (imposterId: string | null) => void;
  logout: (noRedirect?: boolean) => Promise<void>;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");

  return context;
};

import * as React from "react";
import { type SVGProps } from "react";
export const IconStarEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9656 1.4719L13.2213 6.04264C13.3782 6.36054 13.6815 6.58082 14.0324 6.63171L19.0762 7.36472C19.9602 7.49321 20.3127 8.57874 19.6736 9.20161L16.0236 12.7594C15.77 13.0068 15.654 13.3635 15.7141 13.7127L16.5756 18.7365C16.7266 19.6163 15.8029 20.2872 15.0128 19.8721L10.5013 17.5004C10.1875 17.3356 9.81248 17.3356 9.49875 17.5004L4.98726 19.8721C4.1971 20.2876 3.27344 19.6163 3.42447 18.7365L4.28596 13.7127C4.34604 13.3635 4.23006 13.0068 3.97641 12.7594L0.326406 9.20161C-0.31273 8.57832 0.0397965 7.49279 0.923406 7.36472L5.96766 6.63171C6.31851 6.58082 6.62181 6.36054 6.77868 6.04264L9.03442 1.4719C9.42908 0.671313 10.5705 0.671313 10.9656 1.4719Z"
      fill="#E0E0E0"
    />
  </svg>
);
